// import axios from "axios";
import React from "react";
import "./ContactUs.scss";

export default function ContactUs() {
  //   function handleSubmit(e) {
  //     e.preventDefault();
  //     console.log(e);
  //     var bodyFormData = new FormData();

  //     bodyFormData.append("csrf_token_name", "bfe5a56ebf00315719e37890a7a380c9");
  //     bodyFormData.append("key", "6ee7846d3455f3a465dfeafd85ff5235");
  //     bodyFormData.append("name", "asdasd");
  //     bodyFormData.append("email", "asdasd@gmail.com");
  //     bodyFormData.append("phonenumber", "123123");
  //     bodyFormData.append("description", "asdasd");

  //     axios({
  //       method: "post",

  //       url: "https://crm.kineticsdubai.com/forms/wtl/6ee7846d3455f3a465dfeafd85ff5235",
  //       withCredentials: false,

  //       headers: {
  //         // csrf_token_name: "bfe5a56ebf00315719e37890a7a380c9",
  //         // key: "6ee7846d3455f3a465dfeafd85ff5235",
  //         "Content-Type": "multipart/form-data",
  //       },
  //       data: bodyFormData,
  //     })
  //       .then(function (response) {
  //         console.log(response);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }
  return (
    <div id="contact" className="contact-us">
      <div className="top">
        <div className="contact-span">Get in touch with us</div>
        <div className="contact-heading">
          A brief about your business and how we can help,
          {/* <br /> */}
          is all it takes to get our creative digital solutions.
        </div>
      </div>
      <div className="bottom">
        {/* <form onSubmit={handleSubmit}>
          <div className="form-row">
            <input
              type="text"
              placeholder="Enter your name"
              name="name"
              id="name"
            />
            <input
              type="email"
              name="email"
              placeholder="Your email"
              id="email"
            />
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              id="subject"
            />
          </div>
          <div className="form-row">
            <textarea
              name="message"
              id="message"
              placeholder="Your message here"
              rows="10"
            ></textarea>
          </div>
          <div className="form-row">
            <button type="submit">Send to Us</button>
          </div>
        </form> */}
        <iframe
          width="100%"
          height="400px"
          src="https://crm.kineticsdubai.com/forms/wtl/6ee7846d3455f3a465dfeafd85ff5235"
          frameBorder="0"
          sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin"
        ></iframe>
      </div>
    </div>
  );
}
