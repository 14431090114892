import React, { useState } from "react";
import PSlider from "../PSlider/PSlider";
import "./PortfolioSection.scss";
import TabbedSlider from "./TabbedSlider/TabbedSlider";

export default function PortfolioSection() {
  const [tabs, setTabs] = useState([
    // "ALL",
    "DESIGN",
    "MARKETING",
    "DEVELOPMENT",
  ]);
  const [selectedTab, setSelectedTab] = useState(0);
  function handleTabClick(tabIndex) {
    console.log(tabIndex);
    setSelectedTab(tabIndex);
  }
  return (
    <div id="portfolio" className="portfolio-section">
      <div className="top">
        <div className="content">
          <h3 className="portfolio-span">OUR PORTFOLIO</h3>
          <div className="portfolio-heading">
            Discover the most{" "}
            <strong>
              fascinating projects <br></br>for our clients
            </strong>
          </div>
        </div>
        <div className="tabs">
          {tabs.map((tab, tabIndex) => {
            return (
              <div
                key={tabIndex}
                className={`tab ${tab === selectedTab ? "active" : ""}`}
                onClick={(e) => handleTabClick(tabIndex)}
              >
                {tab}
              </div>
            );
          })}
        </div>
      </div>
      <div className="bottom">
        <PSlider selected={selectedTab} />
      </div>
    </div>
  );
}
