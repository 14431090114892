import React, { useEffect, useRef } from "react";
import "./Header.scss";
import { gsap } from "gsap";
import { Link } from "react-router-dom";

export default function Header({ clickLink }) {
  let click = "clicked";
  const data = {
    img1: "https://images.unsplash.com/photo-1561070791-2526d30994b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80",
    img2: "https://images.unsplash.com/photo-1561070791-2526d30994b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80",
    img3: "https://images.unsplash.com/photo-1533750516457-a7f992034fec?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1506&q=80",
    img4: "https://images.unsplash.com/photo-1539627831859-a911cf04d3cd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
  };

  const imgWrap = useRef();
  const imgItem = useRef();

  function moveImg(e) {
    var mouseX = e.clientX,
      mouseY = e.clientY;
    var tl = gsap.timeline();
    tl.to(imgWrap.current, {
      duration: 1,
      x: mouseX,
      y: mouseY,
      ease: "power2.out",
    });
  }

  function linkHover(e) {
    console.log(e);
    if (e.type === "mouseenter") {
      var imgSrc = e.target.dataset.src;
      var tl = gsap.timeline();

      tl.set(imgItem.current, {
        attr: { src: imgSrc },
      }).to(imgWrap.current, {
        autoAlpha: 1,
        scale: 1,
      });
    } else if (e.type === "mouseleave") {
      var tl = gsap.timeline();
      tl.to(imgWrap.current, {
        autoAlpha: 0,
        scale: 0.3,
      });
    }
  }

  return (
    <div className="header">
      <section>
        <nav>
          <div ref={imgWrap} className="img-wrapper">
            <div className="img-placeholder">
              <img ref={imgItem} src={data.img1} alt="" />
            </div>
          </div>
          <ul>
            <li>
              <Link
                onMouseEnter={linkHover}
                onMouseLeave={linkHover}
                onMouseMove={moveImg}
                className="nav-link"
                data-src={data.img2}
                onClick={() => clickLink(click)}
                to="/"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                onMouseEnter={linkHover}
                onMouseLeave={linkHover}
                onMouseMove={moveImg}
                className="nav-link"
                data-src={data.img2}
                onClick={() => clickLink(click)}
                to="/about"
              >
                About Us
              </Link>
            </li>
            {/* <li>
              <a
                onMouseEnter={linkHover}
                onMouseLeave={linkHover}
                onMouseMove={moveImg}
                className="nav-link"
                data-src={data.img3}
                href="#"
              >
                Our Services
              </a>
            </li> */}
            <li>
              <Link
                onMouseEnter={linkHover}
                onMouseLeave={linkHover}
                onMouseMove={moveImg}
                className="nav-link"
                data-src={data.img4}
                onClick={() => clickLink(click)}
                to="/contact"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      </section>
    </div>
  );
}
