import React from "react";
import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import "./Separator.scss";
import MarketPlan from "./icons/MarketPlan";
import BestSolution from "./icons/BestSolution";
import ExpertConsultance from "./icons/ExpertConsultance";

export default function Separator() {
  return (
    <div className="separator">
      
      <div className="service">
        <div className="content">
          <h3>Digital Marketing</h3>
          <p>Helping your business to stay on track</p>
        </div>
        <div className="icon">
          <MarketPlan />
        </div>
      </div>
      <div className="service">
        <div className="content">
          <h3>Software Solution</h3>
          <p>Providing the optimum solutions</p>
        </div>
        <div className="icon">
          <BestSolution />
        </div>
      </div>
      <div className="service">
        <div className="content">
          <h3>Expert Consultance</h3>
          <p>Multidisciplinary Team to consult</p>
        </div>
        <div className="icon">
          <ExpertConsultance />
        </div>
      </div>
    </div>
  );
}
