import React from "react";
import "./Map.scss";

export default function Map() {
  return (
    <div className="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.0135790155473!2d55.364684030159154!3d25.236467608065194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d7dde1f5b45%3A0xf38c8fb98b215428!2sDubai%20CommerCity!5e0!3m2!1sen!2seg!4v1676199826543!5m2!1sen!2seg"
        width="100%"
        height="450"
        title="gmap"
        style={{ border: 0, filter: "grayscale(1)" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
