import React from "react";
import "./Countries.scss";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TwitterIcon from "@mui/icons-material/Twitter";

import kineticsmap from "../../assets/Map.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedinCircled from "../../assets/icons/sm-icons/linkedin_circled";
import XCircled from "../../assets/icons/sm-icons/x_circled";

export default function Countries() {
  return (
    <div className="countries">
      <div className="countries-span">COUNTRIES WE WORK IN</div>
      <div className="countries-desc">
        We are allocated in two different countries to effectively reach our
        clients on an International level
      </div>
      <div className="countries-social">
        {/* <a
          href="https://www.instagram.com/kineticsdubaiofficial/"
          target={"_blank"}
          rel="noreferrer"
        >
          <InstagramIcon
            sx={{ background: "#9DAFBD", borderRadius: "50%", padding: "3px" }}
          />
        </a> */}
        <a href="https://twitter.com/KineticsDubai">
          <XCircled />
        </a>
        <a href="https://www.linkedin.com/company/kineticsdubai/">
          <LinkedinCircled />
        </a>
      </div>
      <div className="countries-img">
        <img src={kineticsmap} alt="" />
      </div>
    </div>
  );
}
