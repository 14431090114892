import React from "react";
import ClientsSlider from "../ClientsSlider/ClientsSlider";
import "./ClientsSection.scss";

export default function ClientsSection() {
  return (
    <div id="clients" className="clients-section">
      <div className="top">
        <h3>Our Clients</h3>
        <strong>
          We believe in <span>empowering our clients</span>
        </strong>
        <p>
          with the best digital solutions out there so they can stand out and
          compete in the market.
        </p>
      </div>
      <div className="bottom">
        <ClientsSlider />
      </div>
    </div>
  );
}
