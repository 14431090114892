import React from "react";

function XCircled() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 25 25"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="#9dafbd"></circle>
      <path
        fill="#fff"
        d="M13.93 11.35L19.51 5h-1.32l-4.85 5.51L9.47 5H5l5.86 8.34L5 20h1.32l5.12-5.82L15.53 20H20zm-1.81 2.06l-.6-.83L6.8 6h2l3.81 5.33.6.83 5 6.93h-2z"
      ></path>
    </svg>
  );
}

export default XCircled;
