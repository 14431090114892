import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import bgimg from "../../assets/about-bg.png";
import "./OurTeam.scss";
import chartimg from "../../assets/chart.png";

const OurTeam = () => {
  return (
    <div className="ourteampage">
      <div className="page-header">
        <div className="background">
          <img src={bgimg} alt="" />
        </div>
        {/* <div className="side-content">
          <MailOutlineIcon />
          info@kineticsdubai.com
        </div> */}
        <div className="content">
          <div className="content-span">OUR TEAM</div>
          <div className="content-heading">The Best Team</div>
          <div className="content-desc">
            At Kinetics, We combine creativity, technology, and data to create
            experiences that people want and businesses need. We are in 8
            countries, and we will reach you wherever you are.
          </div>
        </div>
      </div>

      <div className="our-improvement-section">
        <div className="left">
          <h2 className="sectiontitle">
            We're constantly improving our skills to fulfill projects of any
            level
          </h2>
          <p className="sectioncontent">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
          <a href="#" className="outlinedbtn">
            ALL SERVICES
          </a>
        </div>
        <div className="right">
          <div className="charts">
            <div className="chart">
              <img src={chartimg} alt="" />
              <strong className="percentage">68%</strong>
              <strong className="fieldname">Marketing Online</strong>
              <p className="fielddesc">Lorem Ipsum is simply dummy text</p>
            </div>
            <div className="chart">
              <img src={chartimg} alt="" />
              <strong className="percentage">68%</strong>
              <strong className="fieldname">Marketing Online</strong>
              <p className="fielddesc">Lorem Ipsum is simply dummy text</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
