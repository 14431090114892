import React from "react";
import { NavLink, Link } from "react-router-dom";
import "./Header.scss";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../../assets/logo.png";

const Header = () => {
  let activeStyle = {
    borderBottom: "3px solid #fe8033",
    color: "#fe8033",
  };
  return (
    <div className="navbar">
      <div className="left header-style">
        <a href="/">
          <img src={logo} alt="" style={{ height: 30 }} />
        </a>
      </div>
      <div className="middle">
        <NavLink
          // style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="nav-link"
          to="/"
        >
          Home
        </NavLink>
        {/* <NavLink className="nav-link" to="/services">
          Our Services
        </NavLink> */}
        <NavLink className="nav-link" to="/about">
          About Us
        </NavLink>
        <NavLink className="nav-link" to="/contact">
          Contact Us
        </NavLink>
      </div>
      <div className="right">
        <a
          href="https://www.instagram.com/kineticsdubaiofficial/"
          target={"_blank"}
          rel="noreferrer"
        >
          <InstagramIcon
            sx={{
              color: "black",
              fontSize: 18,
            }}
          />
        </a>
        <a href="htps://twitter.com/KineticsDubai">
          <TwitterIcon
            sx={{
              color: "black",
              fontSize: 18,
            }}
          />
        </a>
        <a href="https://www.linkedin.com/company/kineticsdubai/">
          <LinkedInIcon
            sx={{
              color: "black",
              fontSize: 18,
            }}
          />
        </a>
      </div>
    </div>
  );
};

export default Header;
