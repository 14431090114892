import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import MainHeader from "./components/Moved/MainHeader/MainHeader";
import useWindowDimensions from "./hooks/useWindowDimensions";
import AboutUs from "./pages/AboutUs/AboutUs";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import OurServices from "./pages/OurServices/OurServices";
import OurTeam from "./pages/OurTeam/OurTeam";

ReactGA.initialize("G-8GP4WLSLRL");

// // Send a custom event
// ReactGA.event({
//   category: "your category",
//   action: "your action",
//   label: "your label", // optional
//   value: 99, // optional, must be a number
//   nonInteraction: true, // optional, true/false
//   transport: "xhr", // optional, beacon/xhr/image
// });


const Layout = () => {
  // Get pathname to send to GA4
  let location = useLocation();
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, []);
  const { height, width } = useWindowDimensions();

  return (
    <div className="app">
      {width <= 1000 && <MainHeader />}
      {width > 1000 && <Header />}
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />, //TODO: switch with <ComingSoon />
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "/services",
        element: <OurServices />,
      },
      {
        path: "/team",
        element: <OurTeam />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
