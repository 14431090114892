import React, { useRef } from "react";
import AboutUsLine from "../../components/AboutUsLine/AboutUsLine";
import "./Home.scss";
import HeroSection from "../../components/HomeHero/HeroSection";
import FullWidthVideo from "../../components/FullWidthVideo/FullWidthVideo";
import Separator from "../../components/Separator/Separator";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import ClientsSection from "../../components/ClientsSection/ClientsSection";
import TeamSection from "../../components/TeamSection/TeamSection";
import PortfolioSection from "../../components/PortfolioSection/PortfolioSection";
import ContactUs from "../../components/ContactUs/ContactUs";
import Countries from "../../components/Countries/Countries";
import Map from "../../components/Map/Map";
import OurProjects from "../../components/OurProjects/OurProjects";
import sepBg from "../../assets/hulki-okan-tabak-x3kQTL7yw30-unsplash.jpg";
import aboutBg from "../../assets/mert-gulmus-84z6oiWpqvM-unsplash.jpg";
import explore from "../../assets/arrow_up_right_icon.png";
import bottomArrow from "../../assets/bottom_arrow.png";

const Home = () => {
  return (
    <div className="home-whole-wrapper">
      <div className="line-anim-wrapper">
        <div className="page-header">
          <HeroSection />
        </div>
        <div className="services-separator">
        <img src={sepBg} alt="" className="sep-bg" />
        <a href="#ourprojects" className="explore-btn">
          <img src={explore} alt="" />
          <h4>
            Explore the world through the lens of our visual capabilities
          </h4>
        </a>
          <Separator />
        </div>
        {/* <div className="video-section">
          <FullWidthVideo />
        </div> */}
        <section id="about" className="aboutus">
          <div className="sec-content">
          <img src={aboutBg} alt="" className="about-bg" />
          <div className="left">
            <h3>About Us</h3>
            <strong>
              A Leading <span>360 marketing agency</span> providing full fledge
              digital solutions!
            </strong>
            <p className="regular">
              Our story began in 2004 with an unstoppable passion about
              technology and marketing; since then Kinetics has been taking
              steps to stand out and grow. Now, by its talented and dedicated
              team, Kinetics has grown into a multinational company operating in
              14 different countries. Kinetics have been standing out in the
              market knowing that our greatest strength lies in our ability to
              adapt to our clients’ needs. Kinetics promise to exceed your
              expectations from the beginning of our journey till we reach the
              summit together.
            </p>
            <p className="italic">
              Utilizing the latest marketing techniques and technologies to
              design and develop professional marketing solutions
            </p>
            <button>
              <a className="center-readmore" href="/about">Read more</a>
            </button>
            {/* //TODO: Add Outlined Button Component */}
            {/* //TODO: CHANGE ORANGE SHADE TO THIS #e05649 also store in variable and use with variable */}
          </div>
          <div className="right">
            <AboutUsLine />
          </div>
          <div className="scroll-down">
          <a href="#services">
            <img src={bottomArrow} alt="" />
            <p>SCROLL DOWN TO SEE MORE</p>
          </a>
          </div>
        </div>
        </section>
        <ServicesSection />
        <ClientsSection />
        {/* <PortfolioSection /> */}
        <TeamSection />
        {/* <Countries /> */}
        <OurProjects />
        <ContactUs />
        {/* <Map /> */}
      </div>
    </div>
  );
};

export default Home;
