import React from "react";

function BestSolution() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45.867"
      height="47.85"
      viewBox="0 0 45.867 47.85"
    >
      <path
        fill="#d9e6ec"
        d="M1124.785 910.966h-4.985v-4.984a1 1 0 00-1.55-.83l-5.983 3.987-.313.354a21.9 21.9 0 00-24.091 36.292l-1.912 5.736a1 1 0 00.63 1.26l.316.052a1 1 0 00.945-.682l1.7-5.1a21.881 21.881 0 0024.62 0l1.7 5.1a1 1 0 00.946.682l.316-.052a1 1 0 00.63-1.26l-1.912-5.736a21.767 21.767 0 005.431-26.976l.354-.313 3.988-5.981a1 1 0 00-.829-1.55zm-6.98-3.122v3.706l-4.678 4.677.632-5.687zm-6.935 10.64l-2.744 2.743a9.872 9.872 0 00-6.275-2.285 9.968 9.968 0 109.971 9.968 9.871 9.871 0 00-2.286-6.274l2.744-2.743.189-.02a14 14 0 11-1.578-1.578zm-7.025 10.426a1.994 1.994 0 11-1.994-1.994l.486.1-1.191 1.191a1 1 0 101.41 1.409l1.191-1.191zm.02-3.423a3.951 3.951 0 00-2.014-.564 4.032 4.032 0 103.424 1.974l2.852-2.851a7.9 7.9 0 011.7 4.864 7.977 7.977 0 11-7.977-7.975 7.9 7.9 0 014.866 1.7zm-2.014 23.36a19.937 19.937 0 119.787-37.279l-.49 4.407a16.006 16.006 0 103.641 3.64l4.406-.49a19.893 19.893 0 01-17.344 29.722zM1120.225 917l-5.688.632 4.678-4.677h3.707z"
        transform="translate(-1079.915 -904.984)"
      ></path>
    </svg>
  );
}

export default BestSolution;
