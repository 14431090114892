// import React, { useState } from "react";
import "./Footer.scss";
import logo from "../../assets/logo_black.png";
import LinkedinLogo from "../../assets/icons/sm-icons/linkedin_logo";
import InstagramLogo from "../../assets/icons/sm-icons/instagam_logo";
import Xlogo from "../../assets/icons/sm-icons/x_logo";
const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-contents">
        <div className="content">
          <div className="left">
            <img src={logo} alt="" style={{ height: 36 }} />

            <div className="footerlinks">
              <div className="column">
                <h3>Meet Kinetics</h3>
                <a href="/about">About Us</a>
                <a href="#">Our Services</a>
                <a href="#">Our Partners</a>
                <a href="#">Contact Us</a>
              </div>
              <div className="column">
                <h3>Kinetics Services</h3>
                <div className="innercols">
                  <div className="innercolumn">
                    <a href="#">Software Development</a>
                    <a href="#">Mobile Application</a>
                    <a href="#">Web Services</a>
                    <a href="#">Digital Marketing</a>
                    <a href="#">IDX Listing</a>
                  </div>
                  <div className="innercolumn">
                    <a href="#">Content Creation</a>
                    <a href="#">Video Production</a>
                    <a href="#">Branding</a>
                    <a href="#">Startup Services</a>
                    <a href="#">Game Development</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="ourstats">
              <div className="captial">
                <strong>1,150</strong> Community Projects
              </div>
              <div className="captial">
                <strong>485</strong> Total Clients
              </div>
            </div>
            <div className="newslettersection">
              <h3>Email Newsletter</h3>
              <p className="email-section">
                Keep me up to date with content, updates, and offers from
                Kinetics
              </p>
              <div className="newsletter-subscribe">
                {/* <input
                  type="email"
                  placeholder="Email Address"
                  id="email"
                  name="email"
                />
                <a href="#">Subscribe</a> */}
                <iframe
                  width="100%"
                  height="80px"
                  src="https://crm.kineticsdubai.com/forms/wtl/18db7901382480258d67a43bd37caed3?styled=1"
                  frameBorder="0"
                  sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="bottom-border"></div>
        </div>
        <div id="socket">
          <div className="left">
            <div className="quicklinks">
              <a href="#">Privacy Policy</a>
              <a href="#">Terms and Conditions</a>
              <a href="#">Help</a>
              <a href="#">Kinetics Licenses</a>
              <a href="#">Partners</a>
            </div>
            <div className="copyrights">
              © 2023 <a href="#">Kineticsdubai</a> - All Rights Reserved.
            </div>
          </div>
          <div className="right">
            <div className="lang-switch"></div>
            <div className="social-icons">
              <a
                href="https://www.instagram.com/kineticsdubaiofficial/"
                target={"_blank"}
                rel="noreferrer"
              >
                <InstagramLogo />
              </a>
              <a href="https://twitter.com/KineticsDubai">
                <Xlogo />
              </a>
              <a href="https://www.linkedin.com/company/kineticsdubai/">
                <LinkedinLogo />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
