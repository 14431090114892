import React from "react";
import "./AboutUs.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import bgimg from "../../assets/new_aboutbg.png";
import bg_1 from "../../assets/bg_1.png";
import bg_2 from "../../assets/bg_2.png";
import ClientsSlider from "../../components/ClientsSlider/ClientsSlider";
import Milestone from "../../components/Milestone/Milestone";
import Countries from "../../components/Countries/Countries";
import OmniaPic from "../../assets/team/omnia-about.png";
import MinaKamalPic from "../../assets/team/mina-about.png";
import MagedPic from "../../assets/team/maged-about.png";
import AmirPic from "../../assets/team/amir-about.png";

import PartnersSlider from "../../components/PartnersSlider/PartnersSlider";

export default function AboutUs() {
  return (
    <div className="about-page">
      <div className="page-header">
        <div className="background">
          <img src={bgimg} alt="" />
        </div>
        <div className="side-content">
          <a href="mailto:info@kineticsdubai.com">
          <MailOutlineIcon />
        info@kineticsdubai.com
        </a>
        </div>
        <div className="content">
          <div className="content-span">ABOUT US</div>
          <div className="content-heading">We are Kinetics</div>
          <div className="content-desc">
            At Kinetics, We combine creativity, technology, and data to create
            experiences that people want and businesses need. We are in 8
            countries, and we will reach you wherever you are.
          </div>
        </div>
      </div>
      {/* <div className="intro">
        <div className="left">
          <h3>
            Services <span>introduction</span>
            <br /> Company business and <br /> innovation marketing
          </h3>
        </div>
        <div className="right">
          <p>
            We are an Egyptian company founded in 2004 offering your business a
            full-service technology solution. We are a group of developers,
            designers, and marketers who came together to fulfill all your
            digital needs. We believe in empowering our clients with the best
            digital solutions out there so they can stand out and compete in the
            market.
          </p>
        </div>
      </div> */}
      <div className="bg1">
      <img src={bg_1} alt="" />
      <div className="about">
        <div className="about-span">WHO WE ARE?</div>
        <div className="about-catch">
          We are a leading <strong>360 agency</strong> providing digital
          solutions
        </div>
        <div className="about-desc">
          Kinetics is an Egyptian company founded in 2004 offering a
          full-service digital solutions for your business. We are a group of
          professional marketers, designers, developers, and more- who are
          working together to fulfill all your digital needs. Kinetics right use
          the best of each field to deliver promising results through a precise
          market research. Our team main goal is to add prospects to our
          clients’ brands, by effectively using the trending marketing
          techniques and strategies. We believe in empowering our clients with
          the best digital solutions out there so they can stand out and compete
          in the market.
        </div>
        {/* <hr color="#636363" width="240px" /> */}
      </div>
      <div className="clients">
        <div className="clients-span">OUR CLIENTS</div>
        <div className="clients-catch">
          We believe in <strong>empowering our clients</strong>
        </div>
        <div className="clients-desc">
          with the best digital solutions out there so they can stand out and
          compete in the market.
        </div>
        <ClientsSlider />
      </div>
      <div className="milestone">
        <div className="milestone-span">Our Milestones</div>
        <div className="milestone-heading">
          Doing the right thing at the right time
        </div>
        <div className="milestones">
          <div className="box">
            <div className="number">
              <Milestone end="485" />
            </div>
            <div className="name">Clients</div>
          </div>
          <div className="box">
            <div className="number">25+</div>
            <div className="name">Active Accounts</div>
          </div>
          <div className="box">
            <div className="number">30+</div>
            <div className="name">Employees</div>
          </div>
        </div>
      </div>
      </div>
      <div className="bg2">
        <img src={bg_2} alt="" />
      <div className="mission">
        <div className="mission-span">OUR MISSION</div>
        <div className="mission-desc">
          Utilizing the latest marketing techniques and technologies to design
          and develop professional marketing solutions and E-Marketing
          activities that promote our clients business in the marketplace, and
          accelerate their ROI.
        </div>
      </div>
      <div className="vision">
        <div className="vision-span">OUR VISION</div>
        <div className="vision-desc">
          To become a leading digital marketing company in the global
          marketplace by providing highly innovative full digital marketing
          services, to help our clients grow and stand out in the market.
        </div>
      </div>
      <div className="business-partners">
        <div className="partners-span">OUR BUSINESS PARTNERS</div>
        <div className="partners-heading">
          We believe in <strong>empowering our partners</strong>
        </div>
        <div className="partners-desc">
          with the best digital solutions out there so they can stand out and
          compete in the market.
        </div>
        <PartnersSlider />
      </div>
      <div className="founders">
        <div className="founders-span">OUR FOUNDERS</div>
        <div className="founders-heading">
          Our team of <strong>qualified experts </strong> will help you achieve
          your business goals
        </div>
        <div className="founders-boxes">
          
          <div className="founder">
            <div className="founder-img">
              <img src={OmniaPic} alt="" />
            </div>
            <div className="founder-bio">
              <strong>
                Omnia Yehia
              </strong>
              <br></br>
              <p>
              is the co-founder of Kinetics
              Dubai, she’s an AUC graduate, her enthusiasm qualify her to be an
              entrepreneur, she gained many prizes in business development field
              and worked with mega clients from all over the world with a
              premium quality that allows her to get all her clients trust in no
              time She adds a touch that creates innovative style to each client
              she deals with, so generates a premium quality in any field she
              joins.
              </p>
            </div>
          </div>
          <div className="founder">
            <div className="founder-img">
              <img src={AmirPic} alt="" />
            </div>
            <div className="founder-bio">
            <strong>
                Amir Magdy
              </strong>
              <br></br>
              <p>
              is the CEO of Kinetics Dubai, which
              was founded 19 years ago with passion, hardworking and enthusiasm.
              He is always looking forward to discover various investment
              opportunities and lead teams to generate high efficient services,
              SO by his great experience (20 years) in managing huge projects,
              he’s being able to guide the team to reach the highest level in
              every field. He managed over 50+ huge projects so he gained a
              great experience that enabled him to push Kinetics Dubai to be one
              of the most prestigious companies that is well-known by its work
              efficiency and premium quality services that they offer.
              </p>
            </div>
          </div>
          <div className="founder">
            <div className="founder-img">
              <img src={MagedPic} alt="" />
            </div>
            <div className="founder-bio">
              <strong>
                Maged Wagdy
              </strong>
              <br></br>
              <p>
              is the co-founder of Kinetics
              Dubai, he’s graduated from design and art school-miami university,
              he found his passion in arts & designs, so he started his career
              in young age, and with over 15 years of experience in art
              directing. He was able to asset Kinetics Dubai to stand out from
              their competitors. He’s now aiming to put kinetics in a higher
              position in the market by choosing a team that uses all of the
              marketing and advertising tools in a more creative, efficient &
              high quality way.
              </p>
            </div>
          </div>
          <div className="founder">
            <div className="founder-img">
              <img src={MinaKamalPic} alt="" />
            </div>
            <div className="founder-bio">
              <strong>
                Mina Kamal
              </strong>
              <br></br>
              <p>
              is the co-founder of Kinetics Dubai,
              he’s graduated from faculty of computer science- MIU, his passion
              and ambition move him to kick a great start in his career by
              working on a huge projects with many different clients from
              different countries in different fields. His vision is to use the
              newest technology to create the happiest client globally, he
              enjoys big challenges and aims to create a revolutionary
              innovation in the marketing agencies history worldwide
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>

      <Countries />
    </div>
  );
}
