import React, { useState } from "react";
import "./HeroSection.scss";
import LinkedinLogo from "../../assets/icons/sm-icons/linkedin_logo";
import InstagramLogo from "../../assets/icons/sm-icons/instagam_logo";
import Xlogo from "../../assets/icons/sm-icons/x_logo";

const HeroSection = () => {
  return (
    <div className="home-hero-section">
      <div class="side-content">
        <a href="mailto:info@kineticsdubai.com">
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="MailOutlineIcon"
          >
            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path>
          </svg>
          info@kineticsdubai.com
        </a>
      </div>
      <h1>Creative Solutions</h1>
      <div className="bottom-part">
        <h3 className="top">
          The <b>gamechangers</b> of the <b>digital world</b> with a{" "}
          <b>roadmap</b> to success
        </h3>
        <div className="bottom">
          <a href="#contact" className="cta">
            Let's Work Together
          </a>
          <div className="sm-icons">
            <a
              href="https://www.linkedin.com/company/kineticsdubai/"
              target="_blank"
            >
              <LinkedinLogo />
            </a>
            <a href="https://twitter.com/KineticsDubai" target="_blank">
              <Xlogo />
            </a>
            <a
              href="https://www.instagram.com/kineticsdubaiofficial/"
              target="_blank"
            >
              <InstagramLogo />
            </a>
          </div>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
