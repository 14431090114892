import React from "react";

function MarketPlan() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47.997"
      height="47.985"
      viewBox="0 0 47.997 47.985"
    >
      <path
        fill="#d9e6ec"
        d="M702.605 904.984h-34a7.007 7.007 0 00-7 7v33.989a7.007 7.007 0 007 7h34a7.007 7.007 0 007-7v-33.991a7.007 7.007 0 00-7-6.998zm-34 2h16v4a3 3 0 01-3 3h-1.388a4 4 0 100 6h1.388a3 3 0 013 3v5h-5a5 5 0 00-5 5v2h.047a.985.985 0 00.287.511 2 2 0 11-2.668 0 .985.985 0 00.287-.511h.047v-2a5 5 0 00-5-5h-4v-16a5.005 5.005 0 015.003-5.001zm-5 38.988v-15.996h4a3 3 0 013 3v1.387a4 4 0 106 0v-1.387a3 3 0 013-3h5v5a5.005 5.005 0 005 5h2v-.047a.975.975 0 00.511-.287 2 2 0 110 2.667.98.98 0 00-.511-.288v-.046h-2a5.005 5.005 0 00-5 5v4h-16a5.005 5.005 0 01-4.997-5.004zm39 5h-16v-4a3 3 0 013-3h1.387a4 4 0 100-6h-1.387a3 3 0 01-3-3v-5h5a5.005 5.005 0 005-5v-2h-.047a.979.979 0 00-.287-.51 2 2 0 112.668 0 .978.978 0 00-.287.51h-.047v2a5 5 0 005 5h4v15.995a5.005 5.005 0 01-5 5.003zm5-22.993h-4a3 3 0 01-3-3v-1.389a4 4 0 10-6 0v1.388a3 3 0 01-3 3h-5v-5a5.005 5.005 0 00-5-5h-2v.047a.982.982 0 00-.511.287 2 2 0 110-2.667.969.969 0 00.511.286v.047h2a5 5 0 005-5v-4h16a5.005 5.005 0 015 5z"
        transform="translate(-661.608 -904.984)"
      ></path>
    </svg>
  );
}

export default MarketPlan;
