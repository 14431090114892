import React from 'react';
import "./OurProjects.scss";
import image_1 from "../../assets/ProjectsGrid/01.png"
import image_2 from "../../assets/ProjectsGrid/02.png"
import image_3 from "../../assets/ProjectsGrid/03.png"
import image_4 from "../../assets/ProjectsGrid/04.png"
import image_5 from "../../assets/ProjectsGrid/05.png"
import explore from "../../assets/arrow_up_right_icon.png";

const OurProjects = () => {
  return (
    <div className='our-projects-sec' id='ourprojects'>
        <a href="#" className="explore-btn">
          <img src={explore} alt="" />
        </a>
        <h3>
            OUR PROJECTS
        </h3>
        <div className="subtitle">
            We believe in <span>empowering our clients</span>
        </div>
        <p>
            with the best digital solutions out there so they can stand out and compete in the market.
        </p>
        <div className="image-grid">
            <div className="single-col">
                <img src={image_1} alt="" />
                <img src={image_2} alt="" />
            </div>
            <div className="single-col">
                <img src={image_3} alt="" />
                <img src={image_4} alt="" />
                <img src={image_5} alt="" />
            </div>
        </div>
    </div>
  )
}

export default OurProjects