import React from "react";

function LinkedinCircled() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 25 25"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="#9dafbd"></circle>
      <path fill="#fff" d="M5 9.22H8.28V20H5z"></path>
      <path
        fill="#fff"
        d="M16.25 9.22c-1.87 0-2.4.61-2.81 1.41V9.22h-3.28V20h3.28v-6.09c0-.94 0-1.88 1.64-1.88s1.64.94 1.64 1.88V20H20v-6.09c0-2.82-.47-4.69-3.75-4.69z"
      ></path>
      <circle cx="6.64" cy="6.64" r="1.64" fill="#fff"></circle>
    </svg>
  );
}

export default LinkedinCircled;
