import React, { useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import bgimg from "../../assets/about-bg.png";
import "./OurServices.scss";
import PortfolioSection from "../../components/PortfolioSection/PortfolioSection";
import OurPackages from "../../components/OurPackages/OurPackages";
import ContactUs from "../../components/ContactUs/ContactUs";

const OurServices = () => {
  return (
    <div className="services-page">
      <div className="page-header">
        <div className="background">
          <img src={bgimg} alt="" />
        </div>
        <div className="side-content">
          <a href="mailto:info@kineticsdubai.com">
          <MailOutlineIcon />
        info@kineticsdubai.com
        </a>
        </div>
        <div className="content">
          <div className="content-span">Services</div>
          <div className="content-heading">What We Do</div>
          <div className="content-desc">
            At Kinetics, We combine creativity, technology, and data to create
            experiences that people want and businesses need. We are in 8
            countries, and we will reach you wherever you are.
          </div>
        </div>
      </div>
      <div className="intro">
        <div className="left">
          <h3>
            Services <span>introduction</span>
            <br /> Company business and <br /> innovation marketing
          </h3>
        </div>
        <div className="right">
          <p>
            We are an Egyptian company founded in 2004 offering your business a
            full-service technology solution. We are a group of developers,
            designers, and marketers who came together to fulfill all your
            digital needs. We believe in empowering our clients with the best
            digital solutions out there so they can stand out and compete in the
            market.
          </p>
        </div>
      </div>
      <div className="about">
        <div className="about-span">OUR SERVICES</div>
        <div className="about-catch">
          Take the <strong>world’s best services</strong> for your brand
        </div>
        <div className="about-desc">
          We promise to create unforgettable customized experience combining
          creativity and technology to efficiently achieve your brand’s goal.
        </div>
        <div className="services">
          <div className="service-row">
            <div className="service-col" id="digital_marketing">
              <div className="service-head">Digital Marekting</div>
              <div className="service-body">
                Market conditions change rapidly, consumer habits shift, and
                trends begin and end in the blink of an eye. to compete in
                today’s market you need to keep a watchful eye on every small
                detail! Market research is the process of gathering information
                about a target audience and helps you analyze current trends,
                competitors & audience insights that’s relevant to your brand to
                optimize your social media presence and acquire leads.
              </div>
            </div>
            <div className="service-separator"></div>
            <div className="service-col" id="ui_ux">
              <div className="service-head">UX/UI Design</div>
              <div className="service-body">
                Multidisciplinary UX/UI design experts creating visually
                appealing, brand strengthening digital interfaces that attract
                and retain users. At kinetics, our game developers will provide
                you with specialized applications known as electronic games or
                video games either on mobile phones or on personal computers.
                Games will promote team building in businesses, provide your
                kids with educational games.
              </div>
            </div>
          </div>
          <div className="service-row">
            <div className="service-col" id="branding">
              <div className="service-head">Branding</div>
              <div className="service-body">
                Branding is the process of creating a strong, positive
                perception of a company in the customer’s mind Thanks to the
                emergence of digital technology, your brand has an unlimited
                capacity to go where you want and expand your market reach. Our
                branding services utilize technology to fine-tune your company
                image. You can create a company culture from the inside that
                resonates with those on the outside. Branding services include:
                Logo Design, Marketing strategy, Web Design, and Brand Identity
              </div>
            </div>
            <div className="service-separator"></div>
            <div className="service-col" id="mobile_applications">
              <div className="service-head">Mobile Applications</div>
              <div className="service-body">
                Market conditions change rapidly, consumer habits shift, and
                trends begin and end in the blink of an eye. to compete in
                today’s market you need to keep a watchful eye on every small
                detail! Market research is the process of gathering information
                about a target audience and helps you analyze current trends,
                competitors & audience insights that’s relevant to your brand to
                optimize your social media presence and acquire leads.
              </div>
            </div>
          </div>
          <div className="service-row">
            <div className="service-col" id="video_animation">
              <div className="service-head">Video Animation</div>
              <div className="service-body">
                Market conditions change rapidly, consumer habits shift, and
                trends begin and end in the blink of an eye. to compete in
                today’s market you need to keep a watchful eye on every small
                detail! Market research is the process of gathering information
                about a target audience and helps you analyze current trends,
                competitors & audience insights that’s relevant to your brand to
                optimize your social media presence and acquire leads.
              </div>
            </div>
            <div className="service-separator"></div>
            <div className="service-col" id="web_development">
              <div className="service-head">Web Development</div>
              <div className="service-body">
                No matter how small or large your business is, At Kinetics, we
                will provide you with all web related solutions. We aim to
                provide users with all the details they are looking for in order
                to visit again. Our developers will develop professional
                websites updated with the latest technologies to perfectly
                show-up your business.
              </div>
            </div>
          </div>
        </div>
      </div>
      <PortfolioSection />
      <OurPackages />
      <ContactUs />
    </div>
  );
};

export default OurServices;
