import React from "react";

function ExpertConsultance() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47.257"
      height="45.522"
      viewBox="0 0 47.257 45.522"
    >
      <path
        fill="#d9e6ec"
        d="M1490.981 929.971c0-12.654 8.767-21.156 21.814-21.156a.94.94 0 01.942.94v14.38a.938.938 0 01-.294.682.833.833 0 01-.7.257c-.028 0-.2-.019-.227-.02a4.26 4.26 0 104.231 4.256 2.56 2.56 0 00-.039-.382 1.039 1.039 0 01.213-.793.986.986 0 01.706-.367h14.134a.941.941 0 01.942.941c0 13.035-8.384 21.791-20.861 21.791a20.479 20.479 0 01-20.861-20.529zm1.883 0c0 10.631 8.161 18.646 18.982 18.646 11.226 0 18.583-7.411 18.962-18.967h-12.183a6.148 6.148 0 11-6.772-6.452v-12.485c-11.399.397-18.989 8.036-18.989 19.258zm24.571-4.114a.941.941 0 01-.943-.941v-19a.941.941 0 01.943-.94c13.026 0 20.8 7.453 20.8 19.938a.94.94 0 01-.942.941zm.941-1.881h17.961c-.358-10.6-6.846-16.774-17.961-17.1z"
        transform="translate(-1490.981 -904.978)"
      ></path>
    </svg>
  );
}

export default ExpertConsultance;
