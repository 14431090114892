import React, { useEffect, useState } from "react";
import "./MultiSlideSlider.scss";
import Swiper, { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

const MultiSlideSlider = () => {
  useEffect(() => {
    const swiper = new Swiper(".services-swiper", {
      // configure Swiper to use modules
      modules: [Navigation, Autoplay],
      navigation: {
        nextEl: ".swiper-button-next.services-buttons",
        prevEl: ".swiper-button-prev.services-buttons",
      },
      // autoplay: {
      //   delay: 5000,
      //   pauseOnMouseEnter: true,
      // },
      breakpoints: {
        840: {
          slidesPerView: 3,
        },
        540: {
          slidesPerView: 2,
        },
        240: {
          slidesPerView: 1,
        },
      },

      longSwipes: false,
      inverse: true,
    });
  }, []);

  return (
    <div className="multi-slider">
      <div className="nav">
        <div className="swiper-button-next services-buttons"></div>
        <div className="swiper-button-prev services-buttons"></div>
      </div>
      <div className="services-swiper" style={{ overflow: "hidden" }}>
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="slide">
              <div className="index">
                <div className="service-no">01.</div>
              </div>
              <div className="content">
                <div className="service-head">Digital Marketing</div>
                <div className="service-text">
                  Drive website traffic, build awareness & generate higher
                  quality leads using customized SEO, and Media Buying.
                </div>
                <div className="seemore">
                  <a href="#">
                    VIEW SERVICES
                  </a>
                </div>
                {/* <div className="service-cta">VIEW SERVICES</div> */}
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide">
              <div className="index">
                <div className="service-no">02.</div>
              </div>
              <div className="content">
                <div className="service-head">UX/UI Design</div>
                <div className="service-text">
                  Multidisciplinary UX/UI design experts creating visually
                  appealing & brand strengthening interfaces that attract and
                  retain users.
                </div>
                <div className="seemore">
                  <a href="#">
                    VIEW SERVICES
                  </a>
                </div>
                {/* <div className="service-cta">VIEW SERVICES</div> */}
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide">
              <div className="index">
                <div className="service-no">03.</div>
              </div>
              <div className="content">
                <div className="service-head">Web Development</div>
                <div className="service-text">
                  No matter how small or large your business is, At Kinetics, we
                  will provide you with all web related solutions.
                </div>
                <div className="seemore">
                  <a href="#">
                    VIEW SERVICES
                  </a>
                </div>
                {/* <div className="service-cta">VIEW SERVICES</div> */}
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide">
              <div className="index">
                <div className="service-no">04.</div>
              </div>
              <div className="content">
                <div className="service-head">Branding</div>
                <div className="service-text">
                  Our branding services utilize technology to fine-tune your
                  company image. You can create a company culture from the
                  inside that resonates with those on the outside.
                </div>
                <div className="seemore">
                  <a href="#">
                    VIEW SERVICES
                  </a>
                </div>
                {/* <div className="service-cta">VIEW SERVICES</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiSlideSlider;
