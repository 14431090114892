import React, { useState, useEffect } from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import Swiper, { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "./ClientsSlider.scss";
import image6 from "../../assets/partners/final1.png";
import image7 from "../../assets/partners/final2.png";
import image12 from "../../assets/partners/final3.png";
import image4 from "../../assets/partners/final11.png";
import image5 from "../../assets/partners/final16.png";
import image1 from "../../assets/partners/final6.png";
import image2 from "../../assets/partners/final7.png";
import image8 from "../../assets/partners/final8.png";
import image9 from "../../assets/partners/final9.png";
import image10 from "../../assets/partners/final10.png";
import image11 from "../../assets/partners/final4.png";
import image3 from "../../assets/partners/final12.png";
import image13 from "../../assets/partners/final14.png";
import image14 from "../../assets/partners/final15.png";
import image15 from "../../assets/partners/final5.png";
import image16 from "../../assets/partners/1.png";
import image17 from "../../assets/partners/2.png";
import image18 from "../../assets/partners/3.png";
import image19 from "../../assets/partners/4.png";
import image20 from "../../assets/partners/5.png";
import image21 from "../../assets/partners/6.png";
import image22 from "../../assets/partners/7.png";
import image23 from "../../assets/partners/8.png";
import image24 from "../../assets/partners/9.png";
import image25 from "../../assets/partners/10.png";
import image26 from "../../assets/partners/11.png";
import image27 from "../../assets/partners/12.png";
import image28 from "../../assets/partners/13.png";
import image29 from "../../assets/partners/14.png";
import image30 from "../../assets/partners/15.jpg";
import image31 from "../../assets/partners/16.png";
import image32 from "../../assets/partners/18.png";
import image33 from "../../assets/partners/19.png";
import image34 from "../../assets/partners/20.png";
import image35 from "../../assets/partners/21.png";
import image36 from "../../assets/partners/22.png";
import image37 from "../../assets/partners/23.png";

const ClientsSlider = () => {
  const data = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
    image28,
    image29,
    image30,
    image31,
    image32,
    image33,
    image34,
    image35,
    image36,
    image37,
  ];

  useEffect(() => {
    const swiper = new Swiper(".clients-swiper", {
      // configure Swiper to use modules
      modules: [Navigation, Autoplay],
      navigation: {
        nextEl: ".swiper-button-next.client-slider-buttons",
        prevEl: ".swiper-button-prev.client-slider-buttons",
      },
      // autoplay: {
      //   delay: 10000,
      //   pauseOnMouseEnter: true,
      //   slidesPerGroup: 3,
      // },
      breakpoints: {
        840: {
          slidesPerView: 6,
          slidesPerGroup: 3,
          spaceBetween: 30,
        },
        540: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 25,
        },
        240: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 20,
        },
      },
      spaceBetween: 50,
      longSwipes: false,
      // inverse: true,
    });
  }, []);

  return (
    <div className="clients-slider">
      <div className="nav">
        <div className="swiper-button-prev client-slider-buttons"></div>
        <div className="swiper-button-next client-slider-buttons"></div>
      </div>
      <div className="clients-swiper">
        <div className="swiper-wrapper">
          {data.map((client, cix) => {
            return (
              <div key={cix} className="swiper-slide leftt">
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    paddingLeft: 5,
                    paddingRight: 5,
                    width: "95px",
                    height: "95px",
                  }}
                  src={client}
                  alt=""
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ClientsSlider;
