import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import "./MainHeader.scss";
import logo from "../../../assets/logo.png";
export default function MainHeader() {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [linkClicked, setLinkClicked] = useState("click");

  const clickLink = (linkClick) => {
    setLinkClicked(linkClick);
  };

  function handleBurgerClick() {
    setBurgerOpen((prevBurger) => !prevBurger);
  }

  useEffect(() => {
    if (burgerOpen) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "visible";
    }
  }, [burgerOpen]);

  return (
    <div className="main-header pagemargins">
      <div className="logo">
        <img src={logo} alt="" style={{ height: 30 }} />
      </div>
      <div
        id="hamburger"
        className={`hamburglar  ${burgerOpen ? "is-open" : "is-closed"} `}
        onClick={handleBurgerClick}
      >
        <div className="burger-icon">
          <div className="burger-container">
            <span className="burger-bun-top"></span>
            <span className="burger-filling"></span>
            <span className="burger-bun-bot"></span>
          </div>
        </div>

        {/* <!-- svg ring containter --> */}
        <div className="burger-ring">
          <svg className="svg-ring">
            <path
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="3"
              d="M34 2C16.3 2 2 16.3 2 34s14.3 32 32 32 32-14.3 32-32S51.7 2 34 2"
              className="path"
            ></path>
          </svg>
        </div>
        {/* <!-- the masked path that animates the fill to the ring --> */}

        <svg width="0" height="0">
          <mask id="mask">
            <path
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="red"
              strokeMiterlimit="10"
              strokeWidth="4"
              d="M34 2c11.6 0 21.8 6.2 27.4 15.5 2.9 4.8 5 16.5-9.4 16.5h-4"
            ></path>
          </mask>
        </svg>
        <div className="path-burger">
          <div className="animate-path">
            <div className="path-rotation"></div>
          </div>
        </div>
      </div>

      <div className={`drawer  ${burgerOpen ? "open" : "closed"} `}>
        <Header linkClick={clickLink} />
      </div>
    </div>
  );
}
