import React, { useEffect, useState } from "react";
import Swiper, { Autoplay, Navigation } from "swiper";
import "./PSlider.scss";
import Portfolio_1 from "../../assets/portfolio/pics/1.jpg";
import Portfolio_2 from "../../assets/portfolio/pics/2.jpg";
import Portfolio_3 from "../../assets/portfolio/pics/3.jpg";
import Portfolio_4 from "../../assets/portfolio/pics/4.jpg";
import Portfolio_5 from "../../assets/portfolio/pics/5.jpg";
import Portfolio_6 from "../../assets/portfolio/pics/6.jpg";

export default function PSlider(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const data = [
    Portfolio_1,
    Portfolio_2,
    Portfolio_3,
    Portfolio_4,
    Portfolio_5,
    Portfolio_6,
  ];

  useEffect(() => {
    const swiper = new Swiper(".portfolio-swiper", {
      // configure Swiper to use modules
      modules: [Navigation, Autoplay],
      navigation: {
        nextEl: ".swiper-button-next.portfolio-slider-buttons",
        prevEl: ".swiper-button-prev.portfolio-slider-buttons",
      },
      autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        840: {
          slidesPerView: 3,
        },
        540: {
          slidesPerView: 3,
        },
        240: {
          slidesPerView: 2,
        },
      },
      longSwipes: false,
    });
    swiper.on("slidesLengthChange", function () {
      swiper.setProgress(0);
    });
  }, []);

  return (
    <div className="wrap-p">
      <div className="portfolio-swiper">
        <div className="swiper-wrapper">
          {data.map((img, ix) => {
            return (
              <div className="swiper-slide">
                <img src={img} alt="" key={ix} />
              </div>
            );
          })}
        </div>
        <div className="nav">
          <div className="swiper-button-prev portfolio-slider-buttons"></div>
          <div className="swiper-button-next portfolio-slider-buttons"></div>
        </div>
      </div>
    </div>
  );
}
