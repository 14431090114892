import React from "react";
import "./OurPackages.scss";

export default function OurPackages() {
  return (
    <div id="ourpackages">
      <div className="top">
        <div className="content">
          <h3 className="packages-span">OUR PACKAGES</h3>
          <div className="packages-heading">
            Take the <strong>world’s best packages</strong> for your brand
          </div>
          <div className="packages-paragraph">
            We promise to create unforgettable customized experience combining
            creativity and technology to efficiently achieve your brand’s goal.
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="package basic">
          <h3>Basic Package</h3>
          <div className="benefits">
            <p>7 days setup</p>
            <p>No Setup Fees</p>
            <p>Dedicated Account Manager</p>
            <p>8 Hours / Day</p>
            <p>7 Days / Week</p>
            <p>Posting Strategy</p>
            <p>Monthly Calendar & Reports</p>
            <p>English / Arabic Content</p>
            <p>2 Social Media Platforms</p>
            <p>4 Designs</p>
            <p>12 Posts</p>
            <p>12 Creative Stories</p>
          </div>
          <a href="#" className="cta">
            LEARN MORE
          </a>
        </div>
        <div className="package standard">
          <h3>Standard Package</h3>
          <div className="benefits">
            <p>7 days setup</p>
            <p>No Setup Fees</p>
            <p>Dedicated Account Manager</p>
            <p>8 Hours / Day</p>
            <p>7 Days / Week</p>
            <p>Posting Strategy</p>
            <p>Monthly Calendar & Reports</p>
            <p>English / Arabic Content</p>
            <p>3 Social Media Platforms</p>
            <p>8 Designs</p>
            <p>16 Posts</p>
            <p>16 Creative Stories</p>
          </div>
          <a href="#" className="cta">
            LEARN MORE
          </a>
        </div>
        <div className="package pro">
          <h3>Professional Package</h3>
          <div className="benefits">
            <p>7 days setup</p>
            <p>No Setup Fees</p>
            <p>Dedicated Account Manager</p>
            <p>8 Hours / Day</p>
            <p>7 Days / Week</p>
            <p>Posting Strategy</p>
            <p>Monthly Calendar & Reports</p>
            <p>English / Arabic Content</p>
            <p>4 Social Media Platforms</p>
            <p>16 Designs</p>
            <p>24 Posts</p>
            <p>24 Creative Stories</p>
          </div>
          <a href="#" className="cta">
            LEARN MORE
          </a>
        </div>
      </div>
    </div>
  );
}
