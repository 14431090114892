import React from "react";
import Map from "../../components/Map/Map";
import "./Contact.scss";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ContactUs from "../../components/ContactUs/ContactUs";

export default function Contact() {
  return (
    <div className="contact-page">
      <div className="page-header">
        <div className="background">
          <img src="earth.png" alt="" />
        </div>
        <div className="content">
          <div className="content-span">Contact Us</div>
          <div className="content-heading">Always Here for You</div>
          <div className="content-desc">We’d love to hear from you</div>
        </div>
      </div>
      <Map />
      <div className="contact-info">
        <div className="top">
          <div className="heading">
            We are always here to <strong>help you</strong>
          </div>
          <div className="desc">
            We can help! Our team of experts is on hand to answer your inquiries
          </div>
        </div>
        <div className="bottom">
          <div className="box">
            <div className="icon">
              <LocalPhoneOutlinedIcon />
            </div>
            <div className="text">
              <div className="title">Phone</div>
              <div className="info">
                Phone +971 (502) 271 697
                <br />
                <br />
                Phone +971 (508) 163 283
              </div>
            </div>
          </div>
          <div className="box">
            <div className="icon">
              <RoomOutlinedIcon />
            </div>
            <div className="text">
              <div className="title">Address</div>
              <div className="info">
                11 17th St - Umm Ramool - Dubai,
                <br />
                <br />
                United Arab Emirates
              </div>
            </div>
          </div>
          <div className="box">
            <div className="icon">
              <EmailOutlinedIcon />
            </div>
            <div className="text">
              <div className="title">Email</div>
              <div className="info">
                info@kineticsdubai.com
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactUs />
    </div>
  );
}
