import React from "react";
import "./Milestone.scss";
import CountUp from "react-countup";

export default function Milestone(props) {
  return (
    <CountUp
      duration={5}
      scrollSpyOnce={true}
      enableScrollSpy={true}
      end={props.end}
    />
  );
}
