import React from "react";
import MultiSlideSlider from "../MultiSlideSlider/MultiSlideSlider";
import "./ServicesSection.scss";

export default function ServicesSection() {
  return (
    <div id="services" className="our-services">
      <div className="top">
        <h3>Our Services</h3>
        <strong>
          Take the <span>world’s best services</span> for your brand
        </strong>
        <p className="regular">
          We promise to create unforgettable customized experience combining
          creativity and technology to efficiently achieve your brand’s goal.
        </p>
      </div>
      <div className="bottom">
        <MultiSlideSlider />
      </div>
    </div>
  );
}
