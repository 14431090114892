import React from "react";
import "./TeamSection.scss";
import imageamir from "../../assets/team/amir.png";
import imagemaged from "../../assets/team/maged.png";
import imagemina from "../../assets/team/mina.png";
import imageomnia from "../../assets/team/omnia.png";
import sepBg from "../../assets/hulki-okan-tabak-x3kQTL7yw30-unsplash.jpg";

export default function TeamSection() {
  return (
    <div className="team-section">
      <img className="sec-bg" src={sepBg} alt="" />
      <div className="sec-content">
        <h2>OUR FOUNDERS</h2>
        <div className="subtitle">
          Our <span>founders of experienced professionals</span> to get fast
          profit for you
        </div>
        <p>
          Our founders have the experience that enable them to push kinetics to
          be one of the most prestigious companies worldwide that is well-known
          by its work efficiency and premium quality services that they offer.
          Our founders have the experience that enable them to push kinetics to
          be one of the most prestigious companies worldwide that is well-known
          by its work efficiency and premium quality services that they offer.
        </p>
        <div className="team-photos">
          <div className="maged">
            <img src={imagemaged} alt="" />
            <div className="personalinfo">
              <h3>Magued Wagdy</h3>
              <h4>Co-founder & CCO</h4>
            </div>
          </div>
          <div className="omnia">
            <img src={imageomnia} alt="" />
            <div className="personalinfo">
              <h3>Omnia Yehia</h3>
              <h4>Co-founder & CXO</h4>
            </div>
          </div>
          <div className="amir">
            <img src={imageamir} alt="" />
            <div className="personalinfo">
              <h3>Amir Magdy</h3>
              <h4>Co-founder & CEO</h4>
            </div>
          </div>
          <div className="mina">
            <img src={imagemina} alt="" />
            <div className="personalinfo">
              <h3>Mina Kamal</h3>
              <h4>Co-founder & CTO</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
